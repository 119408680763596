(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.vehicles.guests.edit.controller:GuestEditCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.guests.edit')
    .controller('GuestEditCtrl', GuestEditCtrl);

  function GuestEditCtrl($state, $stateParams, $mdDialog, $filter, Restangular, event) {
    var vm = this;
    vm.submit = submit;
    vm.deleteGuest = deleteGuest;
    vm.showConfirmDelete = showConfirmDelete;

    vm.event = event.data;
    vm.vehicle = null;
    vm.guest = {};
    vm.created_at = '';
    vm.updated_at = '';
    vm.isEditing = $state.is('events.vehicles.guests.edit');

    vm.errors = [];

    if (vm.isEditing) {
      Restangular.one('guests', $stateParams.guestId).get().then(function (response) {
        vm.guest = response.data;
        vm.created_at = moment(vm.guest.created_at).format('Do MMM YYYY');
        vm.updated_at = moment(vm.guest.updated_at).format('Do MMM YYYY');

        vm.guest.cost = $filter('dollars')(vm.guest.cost);
      });
    } else {
      vm.guest.cost = $filter('dollars')(vm.event.dinners);
    }

    // Get vehicle if required
    if ($stateParams.vehicleId && !vm.vehicle) {
      Restangular.one('vehicles', $stateParams.vehicleId).get().then(function (response) {
        vm.vehicle = response.data;
        vm.guest.vehicle_id = vm.vehicle.id;
      });
    }

    function submit() {

      var data = angular.copy(vm.guest);
      data.cost = $filter('cents')(vm.guest.cost);

      if (vm.isEditing) {
        Restangular.all('guests').customPUT(data, vm.guest.id).then(submitSuccess, submitFailure);
      } else {
        Restangular.all('guests').post(data).then(submitSuccess, submitFailure);
      }
    }

    function submitSuccess(response) {
      var id = vm.isEditing ? vm.guest.id : response.data.id;
      $state.go('events.vehicles.guests.list', {eventId: vm.event.id, vehicleId: vm.vehicle.id});
    }

    function submitFailure(response) {
      console.log('guest submit failed', response.data.errors);
      vm.errors = response.data.errors;
    }

    function deleteGuest() {
      Restangular.one('guests', vm.guest.id).remove().then(deleteSuccess, deleteFailure);
    }

    function deleteSuccess() {
      $state.go('events.vehicles.guests.list');
    }

    function deleteFailure(response) {
      console.log('failed delete', response);
      $state.go('events.vehicles.guests.list');
    }

    function showConfirmDelete(ev) {
      var confirm = $mdDialog.confirm()
        .title('Delete Guest')
        .textContent('Do really want to delete ' + vm.guest.name + '?')
        .ariaLabel('Confirm delete guest')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        deleteGuest();
      });
    }
  }

}());
